'use strict';

var tealium = require('./tealium');
var utils = require('./utils');

/**
 * Populate event data for keys shared across store finder events
 * @param {Object} data - utag data object
 * @returns {Object} - shared event data object
 */
function getCommonUtagData(data) {
    var COMMON_KEYS = [
        'page_type',
        'page_name',
        'login_status',
        'pdp_state',
        'product_category',
        'product_master',
        'product_id',
        'product_sku',
        'product_name',
        'product_style_number',
        'product_gender',
        'product_silhouette',
        'product_gbu',
        'product_color',
        'product_subcategory',
        'customer_zip',
        'site',
        'site_section',
        'page_group',
        'page_context_type',
        'page_context_title',
        'site_type',
        'element_type',
        'element_text',
        'user_id',
        'in_stock_status',
        'pdp_availability',
        'item_list_id',
        'item_brand',
        'item_category',
        'item_id',
        'item_name',
        'item_variant'
    ];

    return utils.populateFromKeys(COMMON_KEYS, data, {});
}

/**
 * Gather event data and trigger Tealium event with resulting object
 * @param {string} event - event name
 * @param {string} message - event message
 * @param {string} detail - event detail
 * @param {string} detail2 - secondary event detail
 * @param {string} detail3 - tertiary event detail
 * @returns {void}
 */
function triggerTealiumEvent(event, message, detail, detail2, detail3) {
    if (!window.utag_data) {
        return;
    }

    var commonUtagData = getCommonUtagData(window.utag_data);
    var eventDetails = {
        event_type: event,
        event_message: message,
        event_detail: detail,
        event_detail2: detail2,
        event_detail3: detail3,
        ga4_event: 'user_tool'
    };
    var eventObject = Object.assign({}, commonUtagData, eventDetails);

    tealium.triggerEvent(eventObject);
}

var storeFinder = {
    init: function () {
        $(document).on('storeFinder:searchPostalCode', function () {
            triggerTealiumEvent(
                'bopis_select_a_store_zipcode_input',
                'bopis_select_a_store_input',
                'bopis',
                'Accepted Locations Permissions',
                'select_a_store_zipcode_input'
            );
        });

        $(document).on('storeFinder:locationPermissionsDeny', function () {
            triggerTealiumEvent(
                'bopis_select_a_store_modal_button',
                'bopis_select_a_store_modal_button',
                'bopis',
                'Declined Location Permissions'
            );
        });

        $(document).on('storeFinder:locationPermissionsAccept', function () {
            triggerTealiumEvent(
                'bopis_select_a_store_modal_button',
                'bopis_select_a_store_modal_button',
                'bopis',
                'Accepted Location Permissions',
                'Near Me Button'
            );
        });

        $(document).on('storeFinder:bopisChangeStoreLink', function () {
            triggerTealiumEvent(
                'bopis_change_store_link',
                'bopis_change_store_link',
                'change_store'
            );
        });
    }
};

storeFinder.init();
