/* eslint-disable no-undef */
/* eslint-disable require-jsdoc */

'use strict';

var BREAKPOINTS = {
    mobile: {
        query: '(max-width:992px)',
        index: 1
    },
    smallDesktop: {
        query: '(min-width:992px) and (max-width: 1200px)',
        index: 2
    },
    largeDesktop: {
        query: '(min-width: 1200px)',
        index: 3
    }
};

/**
 * We unfortunately need to poll for the presence of utag.js.
 * @returns {Promise} - Resolves with window.utag object.
 */
function getUtag() {
    var begin = Date.now();

    return new Promise(function (resolve, reject) {
        var poll = function () {
            if (window.utag) {
                resolve(window.utag);
                return;
            }

            if (Date.now() - begin < 5000) {
                setTimeout(poll, 200);
            } else {
                reject(new Error('window.utag not found'));
            }
        };

        poll();
    });
}

$(document).ready(function () {
    var currentRequestUserAgent = (function () {
        var userAgent = 'Desktop';
        if (!window.navigator) {
            userAgent = 'Desktop';
        }
        var navUserAgent = navigator.userAgent;
        if (navUserAgent) {
            // touch/mobile detection
            if (
                navUserAgent.match(/Phone/i)
                || navUserAgent.match(/DROID/i)
                || navUserAgent.match(/Android/i)
                || navUserAgent.match(/webOS/i)
                || navUserAgent.match(/iPhone/i)
                || navUserAgent.match(/iPod/i)
                || navUserAgent.match(/BlackBerry/)
                || navUserAgent.match(/Windows Phone/i)
                || navUserAgent.match(/ZuneWP7/i)
                || navUserAgent.match(/IEMobile/i)
            ) {
                userAgent = 'Mobile';
                // touch/tablet detection
            } else if (
                navUserAgent.match(/Tablet/i)
                || navUserAgent.match(/iPad/i)
                || navUserAgent.match(/Kindle/i)
                || navUserAgent.match(/Playbook/i)
                || navUserAgent.match(/Nexus/i)
                || navUserAgent.match(/Xoom/i)
                || navUserAgent.match(/SM-N900T/i) // Samsung Note 3
                || navUserAgent.match(/GT-N7100/i) // Samsung Note 2
                || navUserAgent.match(/SAMSUNG-SGH-I717/i) // Samsung Note
                || navUserAgent.match(/SM-T330NU/i) // Samsung Tab 4
            ) {
                userAgent = 'Tablet';
            }
        }
        return userAgent;
    }());

    if (currentRequestUserAgent) {
        getUtag().then(function (utag) {
            // eslint-disable-next-line no-param-reassign
            utag.data.site_type = currentRequestUserAgent;
        }).catch(function (error) {
            if (window.console) {
                // eslint-disable-next-line no-console
                console.warn(error.message);
            }
        });
    }
});

var fireTealium = function (eventObj) {
    var tealium = require('./tealium');
    if (!window.utag_data) {
        return;
    }
    var finalObj = {
        page_name: window.utag_data.page_name,
        page_location: window.location.pathname,
        page_type: window.utag_data.page_type !== undefined ? window.utag_data.page_type : '',
        page_group: window.utag_data.page_group !== undefined ? window.utag_data.page_group : ''
    };

    if (eventObj && Object.keys(eventObj).length > 0) {
        Object.keys(eventObj).forEach(function (key) {
            if (finalObj[key] === undefined) {
                finalObj[key] = eventObj[key];
            }
        });
    }

    tealium.triggerEvent(finalObj);
};

var utils = {
    /**
     * Maps a collection of keys to their values
     * @param {Object} keys - parameters for analytics data
     * @param {Object} data - data object with values
     * @param {Object} assignTo - parameter properties
     * @returns {Object} mapping of keys to values
     */
    populateFromKeys: function populateFromKeys(keys, data, assignTo) {
        var obj = assignTo;
        if (keys) {
            keys.forEach(function (key) {
                obj[key] = data[key];
            });
        }
        return obj;
    },

    /**
    * Returns common utag product data
    * @param {Object} data - client side data object
    * @returns {Object} - object with tealiumData
    */
    getCommonUtagProductData: function getCommonUtagProductData(data) {
        var tealiumData = {};
        if (data) {
            var keys = ['adobe_product_id', 'product_brand', 'product_category', 'product_color', 'product_gender', 'product_id', 'product_impression_id',
                'product_line', 'product_list', 'product_master', 'product_name', 'product_quantity', 'product_size', 'product_sku', 'product_silhouette',
                'product_style_number', 'product_subcategory', 'product_variant_id', 'product_vat', 'product_width', 'product_gbu', 'product_type'];
            tealiumData = utils.populateFromKeys(keys, data, {});
            tealiumData.item_id = data.product_id;
            tealiumData.item_brand = data.product_brand;
            tealiumData.item_name = data.product_name;
            tealiumData.item_variant = data.product_variant_id;
            tealiumData.item_category = data.product_category;
            tealiumData.item_category2 = data.product_line;
            tealiumData.item_category3 = data.product_gender;
            tealiumData.item_category4 = data.product_color;
            tealiumData.item_category5 = data.product_style_number;
            tealiumData.product_sku = data.product_sku;
            tealiumData.price = data.product_unit_price;
            tealiumData.quantity = data.product_quantity;
            tealiumData.category_path = data.category_path;
            tealiumData.original_product_unit_price = data.original_product_unit_price;
        }
        return tealiumData;
    },

    /**
    * Returns common utag product data
    * @param {Object} data - client side data object
    * @returns {Object} - object with tealiumData
    */
    getCommonUtagSiteData: function getCommonUtagSiteData(data) {
        var tealiumData = {};
        if (data) {
            var keys = ['page_name', 'page_group', 'page_context_type', 'page_context_title',
                'site', 'site_type', 'site_section', 'login_status', 'user_id'];
            tealiumData = utils.populateFromKeys(keys, data, {});
        }

        return tealiumData;
    },

    fireTealium: fireTealium,

    /**
    * Check if we are on a mobile breakpoint.
    * @returns {boolean} - True or false.
    */
    isMobile: function isMobile() {
        return window.matchMedia(BREAKPOINTS.mobile.query).matches;
    }

};

module.exports = utils;
