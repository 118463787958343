/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable require-jsdoc */
/* eslint-disable eqeqeq */
/* eslint camelcase: [0, {properties: "never"}] */

'use strict';

var tealium = require('./tealium');
var measures = require('./utils');
var focusStart = false;
var search = {
    addParamToUrl: function (element, attr, paramName, paramValue) {
        var url = element.attr(attr);
        var urlParams = new URLSearchParams(url.split('?')[1]);
        if (!urlParams.has(paramName)) {
            urlParams.append(paramName, paramValue);
            url = url.split('?')[0] + '?' + urlParams.toString();
        }
        element.attr(attr, url);
        return url;
    },
    init: function () {
        function suggetionTile() {
            var searchSelector = measures.isMobile() ? '.search-mobile .site-search' : '.header-nav .site-search';
            var searchTerm = $(searchSelector).find('input')[0].value;
            var suggestionDataObject = {
                event_type: 'enhancedSearch_productResults',
                page_name: window.utag_data.page_name,
                event_message: 'Enhanced Search | Product Result | ' + searchTerm,
                search_term: searchTerm,
                ga_label: searchTerm,
                ga_action: 'Product Results',
                enhanched_search_click: searchTerm
            };
            return suggestionDataObject;
        }
        // Search product tile click event
        $(document).on('click', '.suggestions .product-items img', function (e) {
            if (window.utag_data) {
                e.preventDefault();
                var isRecommendations = $(this).closest('.product-items').hasClass('tl_suggestion_trending_products');
                var productImgEventObject = suggetionTile();
                var updatedUrl = search.addParamToUrl($(this).closest('a'), 'href', 'sm', isRecommendations ? 'Recommendations For You Results' : 'Top Results');
                if (productImgEventObject.search_term) {
                    updatedUrl = search.addParamToUrl($(this).closest('a'), 'href', 'pdq', productImgEventObject.search_term);
                }
                tealium.triggerEvent(productImgEventObject);
                window.location.href = updatedUrl;
            }
        });

        $(document).on('click', '.suggestions .product-items .pdp-link', function (e) {
            if (window.utag_data) {
                e.preventDefault();
                var isRecommendations = $(this).closest('.product-items').hasClass('tl_suggestion_trending_products');
                var productLinkEventObject = suggetionTile();
                var updatedUrl = search.addParamToUrl($(this).find('a'), 'href', 'sm', isRecommendations ? 'Recommendations For You Results' : 'Top Results');
                if (productLinkEventObject.search_term) {
                    updatedUrl = search.addParamToUrl($(this).find('a'), 'href', 'pdq', productLinkEventObject.search_term);
                }
                tealium.triggerEvent(productLinkEventObject);
                window.location.href = updatedUrl;
            }
        });

        // Top Search result
        $('.tl_top_searches a, .custom-top-searches a').click(function (e) {
            if (window.utag_data) {
                e.preventDefault();
                var updatedUrl = search.addParamToUrl($(this), 'href', 'sm', 'Top Searches Results');
                var topSearchEventObject = suggetionTile();
                topSearchEventObject.event_type = 'enhancedSearch_topCategories';
                topSearchEventObject.ga_action = 'Top Categories';
                topSearchEventObject.event_message = 'Enhanced Search | Top Categories | ' + $(e.target).text().trim();
                tealium.triggerEvent(topSearchEventObject);
                window.location.href = updatedUrl;
            }
        });

        // Top recent Search result
        $('.tl_recent_searches a').click(function (e) {
            if (window.utag_data) {
                e.preventDefault();
                var updatedUrl = search.addParamToUrl($(this), 'href', 'sm', 'Your Recent Searches Results');
                var recentSearchEventObject = suggetionTile();
                recentSearchEventObject.event_type = 'enhancedSearch_recentSearches';
                recentSearchEventObject.ga_action = 'Recent Searches';
                recentSearchEventObject.event_message = 'Enhanced Search | Recent Searches | ' + $(e.target).text().trim();
                tealium.triggerEvent(recentSearchEventObject);
                window.location.href = updatedUrl;
            }
        });

        // Top clicked result
        $('.tl_search_recent_click a').click(function (e) {
            if (window.utag_data) {
                var suggestionDataLinkObject = suggetionTile();
                suggestionDataLinkObject.event_type = 'enhancedSearch_results_click';
                suggestionDataLinkObject.ga_action = 'Clicked Result';
                suggestionDataLinkObject.event_message = 'Enhanced Search | Clicked Result | ' + $(e.target).text().trim();
                tealium.triggerEvent(suggestionDataLinkObject);
            }
        });

        // Did you mean event
        $('.suggestions .search-phrase a').click(function (e) {
            if (window.utag_data) {
                e.preventDefault();
                var searchTitle = $('.site-search').find('input')[0].value;
                // Search Did you mean click event
                var didYouMeanEventObject = {
                    event_type: 'enhancedSearch_didyouMean',
                    page_name: window.utag_data.page_name,
                    event_message: 'Enhanced Search | didyouMean | ' + searchTitle,
                    enhanched_search_click: searchTitle,
                    search_term: searchTitle,
                    ga_action: 'Suggested Search Term',
                    ga_label: searchTitle
                };
                tealium.triggerEvent(didYouMeanEventObject);

                var updatedUrl;
                if ($(this).closest('.search-terms-suggestions').find('#search-terms-suggestions').hasClass('tl_did_you_mean')) {
                    updatedUrl = search.addParamToUrl($(this), 'href', 'sm', 'Did You Mean Search Results');
                } else {
                    updatedUrl = search.addParamToUrl($(this), 'href', 'sm', 'Suggestions Search');
                }
                window.location.href = updatedUrl;
            }
        });

        // Close the module
        $('.suggestions .close').click(function () {
            focusStart = false;
            if (window.utag_data) {
                // Search close click event
                var closeSuggestionObject = {
                    event_type: 'enhancedSearch_close',
                    page_name: window.utag_data.page_name,
                    enhanched_search_click: 'Close',
                    search_term: $('.site-search').find('input')[0].value,
                    ga_action: 'Exit Button',
                    ga_label: ''
                };
                tealium.triggerEvent(closeSuggestionObject);
            }
        });

        // Search see result click event
        $('.see-all').click(function () {
            if (window.utag_data) {
                var searchTitle = $('.site-search').find('input')[0].value;
                var seeResultData = {
                    event_type: 'enhancedSearch_viewAll',
                    page_name: window.utag_data.page_name,
                    event_message: 'Enhanced Search | View All | ' + searchTitle,
                    enhanched_search_click: searchTitle,
                    search_term: searchTitle,
                    ga_action: 'View All',
                    ga_label: searchTitle
                };
                tealium.triggerEvent(seeResultData);
            }
        });
    }

};

// On Ajax request completed wait for 2 seconds for initialization of DOM
window.suggetionBoxDomReady = function () {
    setTimeout(function () {
        search.init();
    }, 1000);
};

// Focus search field.
$('.tl_site_search').on('focus', function () {
    if (window.utag_data && !focusStart) {
        focusStart = true;
        var startDataObject = {
            event_type: 'enhancedSearch_start',
            page_name: window.utag_data.page_name,
            event_message: 'enhancedSearch_start',
            enhanched_search_click: $('.site-search').find('input')[0].value,
            search_term: $('.site-search').find('input')[0].value,
            ga_action: 'Open Search',
            ga_label: ''
        };
        tealium.triggerEvent(startDataObject);
    }
});
